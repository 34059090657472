$base-color: #000000;
$text-color: rgb(135, 135, 135);

* {
  box-sizing: border-box;
}

html,
body {
  // background-color: #3c3c3c;
  // background-color: #1d1d1d;
  color: #000000;
  // background-color: #525252;
  color: rgb(218, 218, 193);
  color: $text-color;
  font-family: Times New Roman;
  margin: 0;
  padding: 0;
  font-size: 20px;
  height: 100%;
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-attachment: fixed;
}


nav {
  height: 50px;
  position: fixed;
  z-index: 4;
  width: 100%;
  background-color: #000000;
  border-bottom: 2px solid #878787;
}

.mix-wrapper {
  // width: 300px;
  // margin: 40px 30px;
}

.title {
  padding: 13px;
  width: min-content;
  cursor: default;
}

.mix-artwork {
  // width: 305px;
  height: 305px;
  cursor: pointer;
  transition: 0.5s;
  background: #878787;
  position: relative;
}

.soundcloud-embed-wrap {
  background: #5c5c5c;
  height: 305px;
}

.tracklist-modal {
  background-color: rgba(135, 135, 135, 0.8);
  background-color: rgba(218, 218, 193, 0.69);
  background: rgba(255, 255, 255, 0.17);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  color: $text-color;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  white-space: pre-wrap;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.tracklist-section {
  display: flex;
}

.tracklist-wrapper {
  background-color: $base-color;
  color: $text-color;
  border: 2px solid $text-color;
  padding: 10px 90px 10px 10px;
  font-size: 14px;
  text-indent: -5px;
  animation: fadeIn 0.5s;
}

.tracklist-closed {
  display: none;
}

@media only screen and (max-width: 600px) {
  .tracklist-wrapper {
    font-size: 11px;
    padding: 10px;
    max-width: 300px
  }
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 15px;
  line-height: 1.4;
}

.open-mix {
  display: flex;
}

.open-mix-options {
  background-color: $text-color;
  height: min-content;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.open-tracklist-modal {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
}

.open-tracklist-modal {
  margin: 5px 4px 4px 4px;
  transition: 0.2s;
}

.open-tracklist-modal:hover {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
  transition: 0.2s;
}

.close-mix-window {
  transition: 0.2s;
  margin: 4px 4px 0px 4px;
}

.close-mix-window:hover {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(90%) contrast(50%);
  transition: 0.2s;
}

.closed-mix {
  // background: $text-color;
  height: 305px;
  // width: max-content;
  width: 331px;
}

.mix-artwork:hover {
  // opacity: 0.7;
  filter: contrast(0.7);
  transition: 0.5s;
}

.close-tracklist {
  height: min-content;
  margin: 4px 4px 0px;
  transition: 0.2s;
  cursor: pointer;
}

.close-tracklist:hover {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(90%) contrast(50%);
  transition: 0.2s;
}

.close-tracklist-wrapper {
  background-color: $text-color;
  height: min-content;
}

.tracklist-toggle {
  color: $text-color;
  width: min-content;
  display: inline;
  float: right;
}

a {
  color: #000000;
  padding: 20px 14px 0px 12px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.5s;
}

a:hover {
  color: rgb(224, 224, 224);
  transition: 0.5s;
}

.footer {
  bottom: 0;
  padding: 6px;
  margin-top: auto;
  z-index: 0;
  display: flex;
  justify-content: flex-end;
}


.mix-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  z-index: 2;
  padding-left: 26px;
}

.mix-wrapper {
  // flex: 80%;
  padding: 0 7px 33px 0;
  // padding-bottom: 26px;
  display: flex;
  // justify-content: center;
  // padding: 26px 0px 0px 0px;
}

.mix-wrapper:nth-child(odd) {
  // justify-content: flex-end;
}

main {
  background-color: $text-color;
  background-color: #525252;
  // background: repeating-linear-gradient(0deg,
  //     $text-color 2px,
  //     $base-color 5px,
  //     #000000 4px,
  //     rgb(0, 0, 0) 6px,
  //     rgb(91, 91, 91) 8px,
  //     $base-color 2px);
  // background-size: 200% 200%;
  // animation: background-move 2000s ease-in-out infinite;
}

@keyframes background-move {
  100% {
    background-position: 100% 100%;
  }

  50% {
    background-position: 50% 50%;
  }
}

.App {
  // cursor: none;
}

// .cursor-style {
//   // background-color: $text-color;
//   background: repeating-linear-gradient(180deg,
//       $text-color 1px,
//       rgb(1,1,1,) 2px,
//       #ffffff 3px,
//       rgba(216, 216, 216, 0.625) 1px,
//       rgba(255, 0, 0, 0) 2px,
//       $base-color 5px);
//   height: 50px;
//   width: 50px;
//   // border: 1px solid black;
//   // box-shadow: 2px 2px #000000;
//   transform-origin: 100% 100%;
//   transform: translate(-50%, -50%);
//   position: fixed;
//   pointer-events: none;
//   z-index: 100;
// }

.top-layer {
  background-color: $text-color;
  height: 10000px;
  width: 10000px;
  background: repeating-linear-gradient(180deg,
      $text-color 2px,
      rgb(1, 1, 1, ) 7px,
      #ffffff 4px,
      rgba(216, 216, 216, 0.625) 7px,
      rgba(255, 0, 0, 0) 9px,
      $base-color 2px);
  // border: 1px solid black;
  // opacity: 0.5;
  box-shadow: 2px 2px #000000;
  transform-origin: 200% 200%;
  transform: translate(-50%, -50%);
  position: fixed;
  pointer-events: none;
  z-index: 1;
  animation: 5s infinite 1s fadeIn;
  animation-direction: alternate;
  // animation: initialLoad 10s normal forwards ease-in-out;
}

.open-mix-options {
  animation: fadeIn 1s;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}



@keyframes initialLoad {
  0% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

// .mix-artwork,
// .soundcloud-embed-wrap,
// .open-mix-options {
//   box-shadow: 7px 10px 9px #5d5d5d;
// }

.soundcloudIcon {
  width: 30px;
}


.open {
  opacity: 0;
  background-color: #000000;
  transition: opacity 0.4s;
}

.closed-mix-overlay {
  width: 305px;
  height: 305px;
  background-color: #5c5c5c;
}